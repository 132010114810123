body {
    background-color: #d5d5d5;
}

.app-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0 5%;
}

.image-container,
.form-and-answers-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-and-answers-container {
    display: flex;
    gap: 1rem;
}

form, .answers-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.answers-container {
    width: 30%;
}

img {
    max-width: 100%;
    max-height: 100%;
}

input {
    border: 1px solid #d1d5db;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #2563eb;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: 1rem;
}
